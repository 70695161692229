import React from "react";
import Layout from "../components/Layout";

type Props = { location: Location };

const NotFoundPage = ({ location }: Props) => {
  return (
    <Layout location={location}>
      <h1>404: Not Found</h1>
    </Layout>
  );
};

export default NotFoundPage;
